<template>
  <div class="customer-profiling-filters">
    <div
      v-if="hasNoFilters"
      class="customer-profiling-filters__content"
    >
      <div class="customer-profiling-filters__empty-state">
        No filters added!
        <br>
        Please select columns you want to filter, by clicking on ‘+ Add filters’ from footer.
      </div>
    </div>
    <div
      v-else
      class="customer-profiling-filters__content"
    >
      <div
        v-if="hasInactiveFilters"
        class="customer-profiling-filters__section"
      >
        <div class="customer-profiling-filters__section-header">
          Inactive filters ({{ inactiveFilters.length }})
        </div>
        <div class="customer-profiling-filters__section-content">
          <CustomerProfilingFiltersField
            v-for="filter in inactiveFilters"
            :key="filter.id"
            :model-value="filter"
            :error="getFilterError(filter)"
            @update:model-value="updateFilter"
            @remove="removeFilter"
          />
        </div>
      </div>
      <div
        v-if="hasActiveFilters"
        class="customer-profiling-filters__section"
      >
        <div class="customer-profiling-filters__section-header">
          Active filters ({{ activeFilters.length }})
        </div>
        <div class="customer-profiling-filters__section-content">
          <CustomerProfilingFiltersField
            v-for="filter in activeFilters"
            :key="filter.id"
            :model-value="filter"
            :error="getFilterError(filter)"
            @update:model-value="updateFilter"
            @remove="removeFilter"
          />
        </div>
      </div>
    </div>
    <div class="customer-profiling-filters__footer">
      <div class="customer-profiling-filters__button-group">
        <Button
          variant="primary"
          :disabled="isSaveDisabled"
          @click="saveChanges"
        >
          Save
        </Button>
        <Button
          variant="tertiary"
          @click="cancelChanges"
        >
          Cancel
        </Button>
      </div>
      <CustomerProfilingFiltersSelect
        :model-value="localModelValue"
        :options="options"
        @update:model-value="updateAllFilters"
      />
    </div>
  </div>
</template>

<script>
import {
  filter as filterArray,
  cloneDeep,
  every,
  map,
} from 'lodash';
import {
  ref,
  computed,
} from 'vue';
import {
  isFilterInactive,
  isFilterActive,
  isFilterApplied,
} from '@/services/helpers/filters';
import Button from '@/components/common/Button';
import CustomerProfilingFiltersField from './CustomerProfilingFiltersField';
import CustomerProfilingFiltersSelect from './CustomerProfilingFiltersSelect';

export default {
  components: {
    Button,
    CustomerProfilingFiltersField,
    CustomerProfilingFiltersSelect,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  emits: {
    'update:modelValue': {
      type: Array,
    },
    cancel: {
      type: undefined,
    },
  },
  setup(props, { emit }) {
    const localModelValue = ref(cloneDeep(props.modelValue));

    const sidebarFilters = computed(() => filterArray(localModelValue.value, (filter) => !filter.meta.hideSidebar));
    const inactiveFilters = computed(() => filterArray(sidebarFilters.value, isFilterInactive));
    const activeFilters = computed(() => filterArray(sidebarFilters.value, isFilterActive));
    const hasNoFilters = computed(() => !sidebarFilters.value.length);
    const hasInactiveFilters = computed(() => inactiveFilters.value.length);
    const hasActiveFilters = computed(() => activeFilters.value.length);
    const hasChanges = computed(() => JSON.stringify(filterArray(props.modelValue, (filter) => !filter.meta.hideSidebar)) !== JSON.stringify(sidebarFilters.value));
    const hasNoErrors = computed(() => every(activeFilters.value, isFilterApplied));
    const isSaveDisabled = computed(() => !hasChanges.value || !hasNoErrors.value);

    const getFilterError = (filter) => (isFilterActive(filter) ? filter.validator(filter.value) : '');
    const updateAllFilters = (newAllFilters) => {
      localModelValue.value = newAllFilters;
    };
    const updateFilter = (newFilter) => {
      const newLocalModelValue = map(localModelValue.value, (filter) => (newFilter.id === filter.id ? newFilter : filter));
      updateAllFilters(newLocalModelValue);
    };
    const removeFilter = (filter) => {
      const newLocalModelValue = filterArray(localModelValue.value, ({ id }) => filter.id !== id);
      updateAllFilters(newLocalModelValue);
    };
    const saveChanges = () => {
      emit('update:modelValue', localModelValue.value);
    };
    const cancelChanges = () => {
      emit('cancel');
    };

    return {
      localModelValue,
      inactiveFilters,
      activeFilters,
      hasNoFilters,
      hasInactiveFilters,
      hasActiveFilters,
      hasChanges,
      hasNoErrors,
      isSaveDisabled,
      getFilterError,
      updateAllFilters,
      updateFilter,
      removeFilter,
      saveChanges,
      cancelChanges,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-filters {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 64px);

  &__empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    width: 100%;
    color: #191414;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px 16px 0;
    width: 100%;
    height: calc(100% - 64px);
    overflow-y: auto;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-header {
      color: #175FFF;
      font-family: 'Rubik', sans-serif;
      font-weight: 600;
      font-size: 10px;
      line-height: 11.85px;
      text-transform: uppercase;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 0 16px;
    width: 100%;
    height: 64px;
    min-height: 64px;
    max-height: 64px;
    border-top: 1px solid #F0F0F0;
  }

  &__button-group {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;
  }
}
</style>
